/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  GetWeblabByNameInput,
  Metadata,
  WeblabRowType,
  Configs,
} from "../models/API";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Checkbox,
  Grid,
  Divider,
  Tooltip,
  Typography,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  SelectChangeEvent,
  ListItemText,
  ListSubheader,
  IconButton,
  Collapse,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { generateClient } from "aws-amplify/api";
import { getWeblabByName } from "../graphql/queries";
import debounce from "lodash.debounce";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import CurrencyInput from "./utils/CurrencyInput";
import NumberInput from "./utils/NumberInput";
import { FlagIcon } from "./utils/FlagIcon";
import { StatusIcon } from "./utils/StatusIcon";
import { isValidTreatment } from "./utils/formatters";

interface AddWeblabModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (item: WeblabRowType) => void;
  metaData: Metadata;
  configs: Configs;
  initialData?: WeblabRowType | null;
  currentTeamName: string;
  currentTeamId: number;
  isCopy: boolean;
}

interface FormErrors {
  name?: string;
  description?: string;
  experiment_type?: string;
  page_type?: string;
  weblab_team?: string;
  device_type?: string;
  status?: string;
  analysis_duration?: string;
  marketplace?: string;
  control?: string;
  treatment?: string;
  start_date?: string;
  launch_date?: string;
  owners?: string;
  gating_weblab?: string;
  analysis_end_date?: string;
  rev_share?: string;
}

const AddWeblabModal: React.FC<AddWeblabModalProps> = ({
  isOpen,
  onClose,
  onSave,
  metaData,
  configs,
  initialData,
  currentTeamName,
  currentTeamId,
  isCopy,
}) => {
  const [id, setId] = React.useState<number>(
    isCopy ? 0 : initialData?.id! || 0
  );
  const [userName, setUserName] = React.useState(initialData?.user_name! || "");
  const [userId, setUserId] = React.useState(initialData?.user_id!);
  const [weblabTeam, setWeblabTeam] = React.useState(
    initialData?.weblab_team || currentTeamName
  );
  const [name, setName] = React.useState(initialData?.name || "");
  const [weblabId, setWeblabId] = React.useState(initialData?.weblab_id || 0);
  const [description, setDescription] = React.useState(
    initialData?.description! || ""
  );
  const [deepDiveLink, setDeepDiveLink] = React.useState(
    initialData?.deep_dive_link! || ""
  );
  const [comments, setComments] = React.useState(initialData?.comments! || "");
  const [owners, setOwners] = React.useState(initialData?.owners! || "");
  const [globalInitName, setGlobalInitName] = React.useState(
    initialData?.global_init_name! || ""
  );
  const [experimentType, setExperimentType] = React.useState(
    initialData?.experiment_type! || ""
  );
  const [experimentTypeId, setExperimentTypeId] = React.useState<
    number | string
  >(initialData?.experiment_type_id || "");
  const [marketplace, setMarketplace] = React.useState(
    initialData?.marketplace! || ""
  );
  const [marketplaceId, setMarketplaceId] = React.useState<number | string>(
    initialData?.marketplace_id || ""
  );
  const [selectedMarketplaces, setSelectedMarketplaces] = useState<string[]>(
    []
  );
  const [smokeTest, setSmokeTest] = React.useState(
    initialData?.smoke_test || false
  );
  const [control, setControl] = React.useState(initialData?.control! || "C");
  const [treatment, setTreatment] = React.useState(
    initialData?.treatment! || ""
  );
  const [startDate, setStartDate] = React.useState(
    initialData?.start_date! || ""
  );
  const [launchDate, setLaunchDate] = React.useState(
    initialData?.launch_date! || ""
  );
  const [analysisDuration, setAnalysisDuration] = React.useState<number | null>(
    initialData?.analysis_duration || 14
  );
  const [pageType, setPageType] = React.useState(initialData?.page_type! || "");
  const [pageTypeId, setPageTypeId] = React.useState<number | string>(
    initialData?.page_type_id || ""
  );
  const [isGating, setIsGating] = React.useState(
    initialData?.is_gating || false
  );
  const [device, setDevice] = React.useState(initialData?.device! || "");
  const [deviceTypeId, setDeviceTypeId] = React.useState<number | string>(
    initialData?.device_type_id || ""
  );
  const [status, setStatus] = React.useState(initialData?.status! || "");
  const [statusId, setStatusId] = React.useState<number | string>(
    initialData?.status_id || ""
  );

  const [gccpProb, setGccpProb] = React.useState<number | null>(
    initialData?.gccp_prob!
  );
  const [gccpLift, setGccpLift] = React.useState<number | null>(
    initialData?.gccp_lift!
  );
  const [gccpImpact, setGccpImpact] = React.useState<number | null>(
    initialData?.gccp_impact!
  );

  const [opsProb, setOpsProb] = React.useState<number | null>(
    initialData?.ops_prob!
  );
  const [opsLift, setOpsLift] = React.useState<number | null>(
    initialData?.ops_lift!
  );
  const [opsImpact, setOpsImpact] = React.useState<number | null>(
    initialData?.ops_impact!
  );

  const [netCpProb, setNetCpProb] = React.useState<number | null>(
    initialData?.net_cp_prob!
  );
  const [netCpLift, setNetCpLift] = React.useState<number | null>(
    initialData?.net_cp_lift!
  );
  const [netCpImpact, setNetCpImpact] = React.useState<number | null>(
    initialData?.net_cp_impact!
  );

  const [spRevenueProb, setSpRevenueProb] = React.useState<number | null>(
    initialData?.sp_revenue_prob!
  );
  const [spRevenueLift, setSpRevenueLift] = React.useState<number | null>(
    initialData?.sp_revenue_lift!
  );
  const [spRevenueImpact, setSpRevenueImpact] = React.useState<number | null>(
    initialData?.sp_revenue_impact || null
  );

  const [spClicksProb, setSpClicksProb] = React.useState<number | null>(
    initialData?.sp_clicks_prob || null
  );
  const [spClicksLift, setSpClicksLift] = React.useState<number | null>(
    initialData?.sp_clicks_lift || null
  );
  const [spClicksImpact, setSpClicksImpact] = React.useState<number | null>(
    initialData?.sp_clicks_impact || null
  );
  const [paidUnitsProb, setPaidUnitsProb] = React.useState<number | null>(
    initialData?.paid_units_prob || null
  );
  const [paidUnitsLift, setPaidUnitsLift] = React.useState<number | null>(
    initialData?.paid_units_lift!
  );
  const [paidUnitsImpact, setPaidUnitsImpact] = React.useState<number | null>(
    initialData?.paid_units_impact || null
  );
  const [cSalesProb, setCSalesProb] = React.useState<number | null>(
    initialData?.csales_prob || null
  );
  const [cSalesLift, setCSalesLift] = React.useState<number | null>(
    initialData?.csales_lift!
  );
  const [cSalesImpact, setCSalesImpact] = React.useState<number | null>(
    initialData?.csales_impact || null
  );
  const [sbRevenueProb, setSbRevenueProb] = React.useState<number | null>(
    initialData?.sb_revenue_prob || null
  );
  const [sbRevenueLift, setSbRevenueLift] = React.useState<number | null>(
    initialData?.sb_revenue_lift!
  );
  const [sbRevenueImpact, setSbRevenueImpact] = React.useState<number | null>(
    initialData?.sb_revenue_impact || null
  );
  const [gatingWeblab, setGatingWeblab] = React.useState<number | null>(
    initialData?.gating_weblab || 1
  );
  const [revShare, setRevShare] = React.useState<number | null>(
    initialData?.rev_share || 100
  );
  const [revenueImpactPlannedWeblab, setRevenueImpactPlannedWeblab] =
    React.useState<number | null>(
      initialData?.revenue_impact_planned_weblab || null
    );
  const [isChosenBaselineTreatment, setIsChosenBaselineTreatment] =
    React.useState(initialData?.is_chosen_baseline_treatment || false);
  const [irrelevanceRateControl, setIrrelevanceRateControl] = React.useState(
    initialData?.irrelevance_rate_control! || ""
  );
  const [irrelevanceRateTreatment, setIrrelevanceRateTreatment] =
    React.useState(initialData?.irrelevance_rate_treatment! || "");
  const [isPasteManualReports, setIsPasteManualReports] = React.useState(
    initialData?.is_paste_manual_reports || false
  );
  const [analysisStartDate, setAnalysisStartDate] = React.useState(
    isCopy ? "" : initialData?.analysis_start_date! || ""
  );
  const [analysisEndDate, setAnalysisEndDate] = React.useState(
    initialData?.analysis_end_date! || ""
  );
  const [jobIds, setJobIds] = React.useState(initialData?.job_ids! || "");
  const [pirrelHumanRelevanceAudit, setPirrelHumanRelevanceAudit] =
    React.useState(initialData?.pirrel_human_relevance_audit! || "");
  const [ctr, setCtr] = React.useState(initialData?.ctr! || "");
  const [coverage, setCoverage] = React.useState(initialData?.coverage! || "");
  const [cpc, setCpc] = React.useState(initialData?.cpc! || "");
  const [acos, setAcos] = React.useState(initialData?.acos! || "");
  const [isManualWeblab, setIsManualWeblab] = React.useState(
    initialData?.is_manual_weblab || false
  );
  const [isSectionExpanded, setIsSectionExpanded] = React.useState(false);

  const [errors, setErrors] = useState<FormErrors>({});

  const client = generateClient();

  // debounce to call API to avoid any performance issues
  const fetchData = useMemo(
    () =>
      debounce(async (newValue: string) => {
        try {
          const input: GetWeblabByNameInput = {
            weblab_name: newValue,
          };
          const result = await client.graphql({
            query: getWeblabByName,
            variables: {
              input: input,
            },
          });
          const weblab = result.data.getWeblabByName;
          if (weblab != null) {
            setDescription(weblab.description || "");
            setGlobalInitName(weblab.global_init_name || "");
            setDeepDiveLink(weblab.deep_dive_link || "");
            setOwners(weblab.owners || "");
            setExperimentTypeId(weblab.experiment_type_id);
            setPageTypeId(weblab.page_type_id);
            setWeblabId(weblab.id);
            setWeblabTeam(weblab.weblab_team);
          } else {
            setDescription("");
            setGlobalInitName("");
            setDeepDiveLink("");
            setOwners("");
            setExperimentTypeId(0);
            setPageTypeId(0);
            setWeblabId(0);
            setWeblabTeam(configs.teamsDict[currentTeamId]);
          }
        } catch (err) {
          console.error(err);
        }
      }, 500),
    [client, currentTeamId, configs.teamsDict]
  ); // 500 ms

  const debouncedHandleWeblabNameSearch = useCallback(
    (newValue: string) => {
      setName(newValue);
      fetchData(newValue);
    },
    [fetchData]
  );

  useEffect(() => {
    if (isOpen && initialData) {
      setId(isCopy ? 0 : initialData?.id! || 0);
      setWeblabTeam(initialData?.weblab_team || currentTeamName);
      setUserName(initialData?.user_name || "");
      setUserId(initialData?.user_id || 0);
      setName(initialData?.name! || "");
      setWeblabId(initialData?.weblab_id || 0);
      setDescription(initialData?.description! || "");
      setComments(initialData?.comments! || "");
      setOwners(initialData?.owners! || "");
      setGlobalInitName(initialData?.global_init_name! || "");
      setDeepDiveLink(initialData?.deep_dive_link! || "");
      setExperimentType(initialData?.experiment_type! || "");
      setExperimentTypeId(initialData?.experiment_type_id || "");
      setMarketplace(initialData?.marketplace! || "");
      setMarketplaceId(isCopy ? 0 : initialData?.marketplace_id || "");
      setSmokeTest(initialData?.smoke_test || false);
      setControl(initialData?.control || "C"); // default: C
      setTreatment(initialData?.treatment || "");
      setStartDate(initialData?.start_date || "");
      setLaunchDate(initialData?.launch_date || "");
      setAnalysisDuration(initialData?.analysis_duration || null);
      setPageType(initialData?.page_type || "");
      setPageTypeId(initialData?.page_type_id || "");
      setIsGating(initialData?.is_gating || false);
      setDevice(initialData?.device || "");
      setDeviceTypeId(initialData?.device_type_id || "");
      setStatus(initialData?.status || "");
      setStatusId(initialData?.status_id || "");

      setSpClicksImpact(initialData?.sp_clicks_impact);
      setSpClicksLift(initialData?.sp_clicks_lift);
      setSpClicksProb(initialData?.sp_clicks_prob);

      setOpsImpact(initialData?.ops_impact);
      setOpsLift(initialData?.ops_lift);
      setOpsProb(initialData?.ops_prob);

      setNetCpImpact(initialData?.net_cp_impact);
      setNetCpLift(initialData?.net_cp_lift);
      setNetCpProb(initialData?.net_cp_prob);

      setSpRevenueImpact(initialData?.sp_revenue_impact);
      setSpRevenueLift(initialData?.sp_revenue_lift);
      setSpRevenueProb(initialData?.sp_revenue_prob);

      setGccpImpact(initialData?.gccp_impact);
      setGccpLift(initialData?.gccp_lift);
      setGccpProb(initialData?.gccp_prob);

      setPaidUnitsImpact(initialData?.paid_units_impact);
      setPaidUnitsLift(initialData?.paid_units_lift);
      setPaidUnitsProb(initialData?.paid_units_prob);

      setCSalesImpact(initialData?.csales_impact);
      setCSalesLift(initialData?.csales_lift);
      setCSalesProb(initialData?.csales_prob);

      setSbRevenueImpact(initialData?.sb_revenue_impact);
      setSbRevenueLift(initialData?.sb_revenue_lift);
      setSbRevenueProb(initialData?.sb_revenue_prob);

      setIsManualWeblab(initialData?.is_manual_weblab || false);

      if (initialData) {
        setRevShare(initialData?.rev_share);
        setGatingWeblab(initialData?.gating_weblab);
      } else {
        setRevShare(100);
        setGatingWeblab(1);
      }
      setRevenueImpactPlannedWeblab(initialData?.revenue_impact_planned_weblab);
      setIrrelevanceRateControl(initialData?.irrelevance_rate_control || "");
      setIrrelevanceRateTreatment(
        initialData?.irrelevance_rate_treatment || ""
      );
      setIsChosenBaselineTreatment(
        initialData?.is_chosen_baseline_treatment || false
      );
      setIsPasteManualReports(initialData?.is_paste_manual_reports || false);

      setAnalysisStartDate(
        isCopy ? "" : initialData?.analysis_start_date || ""
      );
      setAnalysisEndDate(initialData?.analysis_end_date || "");
      setJobIds(initialData?.job_ids || "");
      setCtr(initialData?.ctr || "");
      setCpc(initialData?.cpc || "");
      setPirrelHumanRelevanceAudit(
        initialData?.pirrel_human_relevance_audit || ""
      );
      setCoverage(initialData?.coverage || "");
      setAcos(initialData?.acos || "");
    } else if (isOpen) {
      setWeblabTeam(currentTeamName);
    }
  }, [metaData, currentTeamId, isOpen, initialData, isCopy, currentTeamName]);

  if (!isOpen) {
    return null;
  }
  const handleToggleSection = () => {
    setIsSectionExpanded((prev) => !prev);
  };
  // @ts-ignore
  const handleWeblabNameChange = (event) => {
    setName(event.target.value);
    event.persist();
    debouncedHandleWeblabNameSearch(event.target.value);
  };

  const handleSave = (e: React.FormEvent) => {
    if (validateForm()) {
      e.preventDefault();
      // Assuming id is auto-incremented in the database
      onSave({
        weblab_team: weblabTeam,
        is_manual_weblab: isManualWeblab,
        acos: acos,
        analysis_end_date: analysisEndDate!,
        analysis_end_date_date: new Date(analysisEndDate!),
        analysis_start_date: isManualWeblab ? analysisStartDate! : "", // always backfill the auto weblab after an update
        analysis_start_date_date: new Date(analysisStartDate!),
        coverage: coverage,
        cpc: cpc,
        ctr: ctr,
        gating_weblab: gatingWeblab,
        irrelevance_rate_control: irrelevanceRateControl,
        irrelevance_rate_treatment: irrelevanceRateTreatment,
        is_chosen_baseline_treatment: isChosenBaselineTreatment,
        is_paste_manual_reports: isPasteManualReports,
        job_ids: jobIds,
        pirrel_human_relevance_audit: pirrelHumanRelevanceAudit,
        rev_share: revShare,
        revenue_impact_planned_weblab: revenueImpactPlannedWeblab,
        gccp_impact: gccpImpact,
        gccp_lift: gccpLift,
        gccp_prob: gccpProb,
        net_cp_impact: netCpImpact,
        net_cp_lift: netCpLift,
        net_cp_prob: netCpProb,
        ops_impact: opsImpact,
        ops_lift: opsLift,
        ops_prob: opsProb,
        sp_clicks_impact: spClicksImpact,
        sp_clicks_lift: spClicksLift,
        sp_clicks_prob: spClicksProb,
        sp_revenue_impact: spRevenueImpact,
        sp_revenue_lift: spRevenueLift,
        sp_revenue_prob: spRevenueProb,
        paid_units_impact: paidUnitsImpact,
        paid_units_lift: paidUnitsLift,
        paid_units_prob: paidUnitsProb,
        csales_impact: cSalesImpact,
        csales_lift: cSalesLift,
        csales_prob: cSalesProb,
        sb_revenue_impact: sbRevenueImpact,
        sb_revenue_lift: sbRevenueLift,
        sb_revenue_prob: sbRevenueProb,
        analysis_duration: analysisDuration,
        bar_raiser_wiki: "",
        device_type_id: deviceTypeId!,
        experiment_type: experimentType,
        experiment_type_id: experimentTypeId!,
        team_preference_id: initialData?.team_preference_id!,
        favorite: initialData?.favorite || false,
        additional_columns: initialData?.additional_columns || JSON.parse("{}"),
        global_init_name: globalInitName,
        deep_dive_link: deepDiveLink,
        is_gating: isGating,
        marketplace_id: marketplaceId!,
        page_type: pageType,
        page_type_id: pageTypeId!,
        smoke_test: smokeTest,
        start_date: startDate!,
        start_date_date: new Date(startDate!),
        status_id: statusId!,
        team_id: currentTeamId,
        control: control,
        device: device,
        marketplace: marketplace,
        marketplaces: selectedMarketplaces.filter(
          (item) =>
            !(Object.keys(configs.regionDict).includes(item) || item === "all")
        ),
        status: status,
        launch_date: launchDate!,
        launch_date_date: new Date(launchDate!),
        treatment: treatment,
        user_id: userId,
        user_name: userName,
        weblab_id: weblabId,
        id: id,
        name: name,
        description: description,
        owners: owners,
        comments: comments,
      });
      handleClose();
    }
  };

  const handleClose = () => {
    // Reset form values
    setId(0);
    setName("");
    setDescription("");
    setComments("");
    setGlobalInitName("");
    setDeepDiveLink("");
    setOwners("");
    setControl("C"); // default: C
    setTreatment("");
    setStartDate("");
    setLaunchDate("");
    setWeblabId(0);
    setWeblabTeam(configs.teamsDict[currentTeamId]);

    setExperimentTypeId("");
    setDeviceTypeId("");
    setPageTypeId("");
    setStatusId("");
    setMarketplaceId("");
    setSelectedMarketplaces([]);

    setAnalysisDuration(14); // default 14 days

    setRevShare(100); // default 100%
    setIsGating(false);
    setGatingWeblab(1); // default 100% experiment weblab
    setRevenueImpactPlannedWeblab(null);
    setIrrelevanceRateControl("");
    setIrrelevanceRateTreatment("");
    setIsManualWeblab(false);
    setIsChosenBaselineTreatment(false);
    setIsPasteManualReports(false);
    setSmokeTest(false);

    setSpClicksImpact(null);
    setSpClicksLift(null);
    setSpClicksProb(null);

    setOpsImpact(null);
    setOpsLift(null);
    setOpsProb(null);

    setNetCpImpact(null);
    setNetCpLift(null);
    setNetCpProb(null);

    setSpRevenueImpact(null);
    setSpRevenueLift(null);
    setSpRevenueProb(null);

    setGccpImpact(null);
    setGccpLift(null);
    setGccpProb(null);

    setPaidUnitsImpact(null);
    setPaidUnitsLift(null);
    setPaidUnitsProb(null);

    setCSalesImpact(null);
    setCSalesLift(null);
    setCSalesProb(null);

    setSbRevenueImpact(null);
    setSbRevenueLift(null);
    setSbRevenueProb(null);

    setAnalysisStartDate("");
    setAnalysisEndDate("");
    setJobIds("");
    setCtr("");
    setCpc("");
    setPirrelHumanRelevanceAudit("");
    setCoverage("");
    setAcos("");

    errors.name = "";
    errors.description = "";
    errors.experiment_type = "";
    errors.page_type = "";
    errors.weblab_team = "";
    errors.device_type = "";
    errors.status = "";
    errors.analysis_duration = "";
    errors.marketplace = "";
    errors.control = "";
    errors.treatment = "";
    errors.start_date = "";
    errors.launch_date = "";
    errors.rev_share = "";
    errors.owners = "";
    errors.gating_weblab = "";
    errors.analysis_end_date = "";
    onClose();
  };

  const validateForm = () => {
    let tempErrors: FormErrors = {};
    if (!name) tempErrors.name = "Weblab ID is required";
    if (!description) tempErrors.description = "Description is required";
    if (description && description.length > 200)
      tempErrors.description = `Short Description is ${description.length} characters, over the 200 limit`;
    if (!experimentTypeId)
      tempErrors.experiment_type = "Experiment type is required";
    if (!pageTypeId) tempErrors.page_type = "Page type is required";
    if (!deviceTypeId) tempErrors.device_type = "Device type is required";
    if (!statusId) tempErrors.status = "Status is required";
    if (
      !marketplaceId &&
      (!selectedMarketplaces || selectedMarketplaces.length === 0)
    )
      tempErrors.marketplace = "Marketplace is required";
    if (!control) tempErrors.control = "Baseline treatment is required";
    else if (!isValidTreatment(control)) {
      tempErrors.control =
        "Invalid format. Please enter 'C' or 'T' followed by a number(e.g., 'T1')";
    }
    if (!treatment) tempErrors.treatment = "Treatment is required";
    else if (!isValidTreatment(treatment)) {
      tempErrors.treatment =
        "Invalid format. Please enter 'C' or 'T' followed by a number(e.g., 'T1')";
    }
    if (!owners) tempErrors.owners = "Owners is required";
    if (isGating && gatingWeblab === null)
      tempErrors.gating_weblab = "Experiment Weblab is required";
    if (
      analysisEndDate !== "" &&
      new Date(analysisEndDate) < new Date(analysisStartDate)
    )
      tempErrors.analysis_end_date =
        "Analysis End Date should be greater than or equal to Analysis Start Date";
    if (
      typeof statusId == "number" &&
      configs.statusesDict[statusId] !== "Planned" &&
      configs.statusesDict[statusId] !== "Paused" &&
      configs.statusesDict[statusId] !== "Not Launched" &&
      configs.statusesDict[statusId] !== "Not Dialed Up"
    ) {
      if (!startDate || startDate === "")
        tempErrors.start_date = "Experiment start date is required";
      if (
        analysisDuration !== null &&
        (analysisDuration <= 0 || analysisDuration >= 29)
      )
        tempErrors.analysis_duration =
          "Analysis duration should be between 1 and 28";
    }
    if (startDate && launchDate) {
      if (new Date(launchDate) < new Date(startDate)) {
        tempErrors.launch_date =
          "Expected launch date cannot be earlier than experiment start date";
      }
    }
    // @ts-ignore
    if (revShare < -100) {
      tempErrors.rev_share = "Revenue share should not be beyond -100";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0; // Return true if no errors
  };

  const reCalculateMarketplaces = (marketplaces: string[]) => {
    let regionCount = 0;
    Object.keys(configs.regionDict).forEach((region) => {
      if (
        configs.regionDict[region].every((item) => marketplaces.includes(item))
      ) {
        marketplaces.push(region); // add region
        regionCount = regionCount + 1;
      } else {
        marketplaces = marketplaces.filter((item) => item !== region); // remove region
      }
    });
    if (regionCount === Object.keys(configs.regionDict).length) {
      marketplaces.push("all");
    } else {
      marketplaces = marketplaces.filter((item) => item !== "all"); // remove region
    }
    setSelectedMarketplaces(Array.from(new Set(marketplaces)));
  };

  const handleMarketplacesChange = async (
    event: SelectChangeEvent<typeof selectedMarketplaces>
  ) => {
    setMarketplaceId("");
    const value = event.target.value as string[];
    let marketplaces: string[] = [];
    if (value.includes("all") && !selectedMarketplaces.includes("all")) {
      // checked "Select All"
      marketplaces = Object.keys(configs.regionDict).flatMap(
        (region) => configs.regionDict[region]
      );
      marketplaces.push("all");
      Object.keys(configs.regionDict).forEach((region) => {
        marketplaces.push(region);
      });
      console.log("checked 'Select All'");
    } else if (
      !value.includes("all") &&
      selectedMarketplaces.includes("all") &&
      selectedMarketplaces.length ===
        Object.keys(configs.marketplaceIdsDict).length +
          Object.keys(configs.regionDict).length +
          1
    ) {
      // unchecked "Select All"
      console.log("Unchecked 'Select All'");
    } else {
      let newValues: string[] = value;
      Object.keys(configs.regionDict).forEach((region) => {
        if (value.includes(region) && !selectedMarketplaces.includes(region)) {
          console.log("checked 'Select '" + region);
          // checked 'Select $region'
          configs.regionDict[region].forEach((marketplace) => {
            marketplaces.push(marketplace);
          });
        } else if (
          !value.includes(region) &&
          selectedMarketplaces.includes(region)
        ) {
          // unchecked 'Select $region'
          console.log("unchecked 'Select '" + region);
          newValues = newValues.filter(
            (item) => !configs.regionDict[region].includes(item)
          );
        }
      });
      // @ts-ignore
      marketplaces.push(...newValues);
    }
    reCalculateMarketplaces(marketplaces);
  };
  const isMarketplaceSelectAllSelected = () => {
    return selectedMarketplaces.includes("all");
  };
  const isRegionSelected = (region: string) => {
    return selectedMarketplaces.includes(region);
  };
  const isMarketplaceSelected = (marketplace: any) => {
    return selectedMarketplaces.includes(marketplace);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Weblab</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Fields marked with * are mandatory.
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Tooltip
              title={"Read Only"}
              placement={"left-start"}
              enterDelay={500}
              leaveDelay={200}
            >
              <TextField
                margin="dense"
                id="id"
                label="ID"
                type="number"
                fullWidth
                variant="outlined"
                value={id}
                InputProps={{
                  readOnly: true,
                  style: {
                    color: "gray", // set font color to gray
                  },
                }}
              />
            </Tooltip>
            <TextField
              multiline
              rows={1}
              size="medium"
              margin="dense"
              id="global_init_name"
              label="Global Init Name"
              type="text"
              fullWidth
              variant="outlined"
              value={globalInitName}
              onChange={(e) => setGlobalInitName(e.target.value)}
            />
            <FormControl
              fullWidth={true}
              margin="dense"
              error={!!errors.page_type}
            >
              <InputLabel id="page-type-select-label">Page Type*</InputLabel>
              <Select
                value={pageTypeId}
                label="Page Type"
                onChange={(e) => setPageTypeId(Number(e.target.value))}
              >
                {metaData.getPageTypes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.page_type}</FormHelperText>
            </FormControl>
            <FormControl
              fullWidth={true}
              margin="dense"
              error={!!errors.experiment_type}
            >
              <InputLabel id="experiment-type-select-label">
                Experiment Type*
              </InputLabel>
              <Select
                value={experimentTypeId}
                label="Experiment Type"
                onChange={(e) => setExperimentTypeId(Number(e.target.value))}
              >
                {metaData.getExperimentTypes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.experiment_type}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              label="Weblab ID"
              type="text"
              fullWidth
              variant="outlined"
              value={name}
              onChange={handleWeblabNameChange}
              error={!!errors.name}
              helperText={errors.name}
              InputProps={{
                readOnly: id !== 0,
                style: {
                  color: id !== 0 ? "gray" : "",
                },
              }}
            />
            <TextField
              required
              multiline
              rows={1}
              size="medium"
              margin="dense"
              id="Weblab_Team"
              label="Weblab Team"
              type="text"
              fullWidth
              variant="outlined"
              value={weblabTeam}
              onChange={(e) => setWeblabTeam(e.target.value)}
              error={!!errors.weblab_team}
              helperText={errors.weblab_team}
            />
            <Tooltip
              title={
                "Enter Amazon aliases seperated by a comma or a space(e.g.,'alias1,alias2')"
              }
              placement={"top-start"}
              enterDelay={500}
              leaveDelay={200}
            >
              <TextField
                required
                multiline
                rows={1}
                size="medium"
                margin="dense"
                id="owners"
                label="Owners"
                type="text"
                fullWidth
                variant="outlined"
                value={owners}
                onChange={(e) => setOwners(e.target.value)}
                error={!!errors.owners}
                helperText={errors.owners}
              />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tooltip
              title={"Weblab Deep Dive/Executive Summary Doc Link"}
              placement={"top-start"}
              enterDelay={500}
              leaveDelay={200}
            >
              <TextField
                rows={2}
                size="medium"
                margin="dense"
                id="deep_dive_link"
                label="Deep Dive Link"
                type="text"
                fullWidth
                variant="outlined"
                value={deepDiveLink}
                onChange={(e) => setDeepDiveLink(e.target.value)}
              />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tooltip
              title={"Short Description(max 200 characters)"}
              placement={"top-start"}
              enterDelay={500}
              leaveDelay={200}
            >
              <TextField
                required
                multiline
                rows={2}
                size="medium"
                margin="dense"
                id="description"
                label="Short Description/Weblab Name"
                type="text"
                fullWidth
                variant="outlined"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                error={!!errors.description}
                helperText={errors.description}
              />
            </Tooltip>
          </Grid>
        </Grid>
        <Divider style={{ margin: "20px 0" }} />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl
              fullWidth={true}
              margin="dense"
              error={!!errors.device_type}
            >
              <InputLabel id="device-type-select-label">
                Device Type*
              </InputLabel>
              <Select
                value={deviceTypeId}
                label="Device Type"
                onChange={(e) => setDeviceTypeId(Number(e.target.value))}
              >
                {metaData.getDeviceTypes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.device_type}</FormHelperText>
            </FormControl>
            {id === 0 ? (
              <FormControl
                fullWidth={true}
                margin="dense"
                error={!!errors.marketplace}
              >
                <InputLabel id="marketplaces-type-select-label">
                  Marketplaces*
                </InputLabel>
                <Select
                  value={selectedMarketplaces}
                  label="Marketplaces"
                  multiple
                  onChange={handleMarketplacesChange}
                  renderValue={(selected) => selected.join(", ")}
                >
                  <MenuItem value={"all"} divider={true}>
                    <Checkbox checked={isMarketplaceSelectAllSelected()} />
                    <ListItemText primary={"Select All"} />
                  </MenuItem>
                  {Object.keys(configs.regionDict).map((region: string) => [
                    <ListSubheader>{region.toUpperCase()}</ListSubheader>,
                    <MenuItem value={region}>
                      <Checkbox checked={isRegionSelected(region)} />
                      <ListItemText primary={"All " + region} />
                    </MenuItem>,
                    configs.regionDict[region].map((marketplace: string) => (
                      <MenuItem
                        key={configs.marketplaceIdsDict[marketplace]}
                        value={marketplace}
                        divider={
                          configs.marketplaceDisplayOrderDict[marketplace] %
                            100 ===
                          0
                        }
                      >
                        <Checkbox
                          checked={isMarketplaceSelected(marketplace)}
                        />
                        {FlagIcon(marketplace)}
                        {marketplace}
                      </MenuItem>
                    )),
                  ])}
                </Select>
                <FormHelperText>{errors.marketplace}</FormHelperText>
              </FormControl>
            ) : (
              <FormControl
                fullWidth={true}
                margin="dense"
                error={!!errors.marketplace}
              >
                <InputLabel id="marketplace-type-select-label">
                  Marketplace*
                </InputLabel>
                <Select
                  value={marketplaceId}
                  label="Marketplace*"
                  onChange={(e) => setMarketplaceId(Number(e.target.value))}
                >
                  {metaData.getMarketplaces.map((option) => (
                    <MenuItem
                      key={option.marketplace_id}
                      value={option.marketplace_id}
                      divider={option.display_order % 100 === 0}
                    >
                      {FlagIcon(option.marketplace)}
                      {option.marketplace}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.marketplace}</FormHelperText>
              </FormControl>
            )}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Experiment Start Date"
                value={startDate ? new Date(startDate) : null}
                onChange={(e) => {
                  if (e instanceof Date && !isNaN(e.getTime())) {
                    setStartDate(e ? e.toLocaleDateString("en-US") : "");
                  } else {
                    setStartDate("");
                  }
                }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    error: !!errors.start_date,
                    helperText: errors.start_date,
                    margin: "dense",
                    size: "medium",
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={isChosenBaselineTreatment}
                      onChange={(e) =>
                        setIsChosenBaselineTreatment(e.target.checked)
                      }
                      color="primary"
                    />
                  }
                  label="Chosen for Launch"
                  labelPlacement="end"
                  sx={{ marginLeft: "auto" }} // Pushes the checkbox to the right
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={isManualWeblab}
                      onChange={(e) => setIsManualWeblab(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Metrics Manually Supplied"
                  labelPlacement="end"
                  sx={{ marginLeft: "auto" }} // Pushes the checkbox to the right
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl
              fullWidth={true}
              margin="dense"
              error={!!errors.status}
            >
              <InputLabel id="status-type-select-label"> Status* </InputLabel>
              <Select
                value={statusId}
                label="Status"
                onChange={(e) => setStatusId(Number(e.target.value))}
              >
                {metaData.getWeblabStatuses.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {StatusIcon(option.name)}
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.status}</FormHelperText>
            </FormControl>
            <Tooltip
              title={"Enter 'C' or 'T' followed by a number(e.g., 'T1')"}
              placement={"left-start"}
              enterDelay={500}
              leaveDelay={200}
            >
              <TextField
                required
                rows={1}
                size="medium"
                margin="dense"
                id="control"
                label="Baseline Treatment"
                type="text"
                fullWidth
                variant="outlined"
                value={control}
                onChange={(e) => setControl(e.target.value)}
                error={!!errors.control}
                helperText={errors.control}
              />
            </Tooltip>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Expected Launch Date"
                value={launchDate ? new Date(launchDate) : null}
                onChange={(e) => {
                  if (e instanceof Date && !isNaN(e.getTime())) {
                    setLaunchDate(e ? e.toLocaleDateString("en-US") : "");
                  } else {
                    setLaunchDate("");
                  }
                }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    error: !!errors.launch_date,
                    helperText: errors.launch_date,
                    margin: "dense",
                    size: "medium",
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={isGating}
                      onChange={(e) => {
                        setIsGating(e.target.checked);
                        if (e.target.checked) {
                          setGatingWeblab(0);
                        }
                      }}
                      color="primary"
                    />
                  }
                  label="Gating Weblab"
                  labelPlacement="end"
                  sx={{ marginLeft: "auto" }} // Pushes the checkbox to the right
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="medium"
              margin="dense"
              id="rev_share"
              label="Revenue Share(%)"
              type="number"
              fullWidth
              variant="outlined"
              value={revShare}
              onChange={(e) => setRevShare(parseInt(e.target.value))}
              error={!!errors.rev_share}
              helperText={errors.rev_share}
            />
            <Tooltip
              title={"Enter 'C' or 'T' followed by a number(e.g., 'T1')"}
              placement={"left-start"}
              enterDelay={500}
              leaveDelay={200}
            >
              <TextField
                required
                rows={1}
                size="medium"
                margin="dense"
                id="treatment"
                label="Experiment Treatment"
                type="text"
                fullWidth
                variant="outlined"
                value={treatment}
                onChange={(e) => setTreatment(e.target.value)}
                error={!!errors.treatment}
                helperText={errors.treatment}
              />
            </Tooltip>
            <Tooltip
              title={"Enter value between 1-28"}
              placement={"left-start"}
              enterDelay={500}
              leaveDelay={200}
            >
              <TextField
                size="medium"
                margin="dense"
                id="analysis_duration"
                label="Analysis Duration (days)"
                type="number"
                fullWidth
                variant="outlined"
                InputProps={{ inputProps: { min: 1, max: 28 } }}
                value={analysisDuration}
                onChange={(e) => {
                  setAnalysisDuration(parseInt(e.target.value));
                  if (e.target.value !== null && analysisStartDate) {
                    let endDate = new Date(analysisStartDate);
                    endDate.setDate(
                      endDate.getDate() + parseInt(e.target.value) - 1
                    );
                    setAnalysisEndDate(endDate.toLocaleDateString("en-US"));
                  }
                }}
                error={!!errors.analysis_duration}
                helperText={errors.analysis_duration}
              />
            </Tooltip>
            <Tooltip
              title={
                "Enter value between 0-1. Enter % Traffic to Experiment Weblab. E.g. If 40% traffic is to gating weblab and 60% to Experiment, please write 0.6 in this field."
              }
              placement={"left-start"}
              enterDelay={500}
              leaveDelay={200}
            >
              <TextField
                size="medium"
                margin="dense"
                id="experiment_weblab"
                label="Traffic Allocation(%) to Experiment"
                type="number"
                fullWidth
                variant="outlined"
                value={gatingWeblab}
                inputProps={{
                  min: "0",
                  max: "1",
                  step: "0.01",
                }}
                onChange={(e) => {
                  const newValue = parseFloat(e.target.value);
                  if (newValue >= 0 && newValue <= 1) {
                    setGatingWeblab(newValue);
                  }
                }}
                error={!!errors.gating_weblab}
                helperText={errors.gating_weblab}
              />
            </Tooltip>
          </Grid>
        </Grid>
        <Divider style={{ margin: "20px 0" }} />
        {isManualWeblab && (
          <div>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Analysis Start Date"
                    value={
                      analysisStartDate ? new Date(analysisStartDate) : null
                    }
                    onChange={(e) => {
                      if (e instanceof Date && !isNaN(e.getTime())) {
                        setAnalysisStartDate(
                          e ? e.toLocaleDateString("en-US") : ""
                        );
                        if (e !== null && analysisDuration !== null) {
                          let endDate = new Date(e);
                          endDate.setDate(
                            // @ts-ignore
                            endDate.getDate() + analysisDuration - 1
                          );
                          setAnalysisEndDate(
                            endDate.toLocaleDateString("en-US")
                          );
                        }
                      } else {
                        setAnalysisStartDate("");
                      }
                    }}
                    slotProps={{
                      textField: {
                        variant: "outlined",
                        margin: "dense",
                        size: "medium",
                        fullWidth: true,
                      },
                    }}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Analysis End Date"
                    value={analysisEndDate ? new Date(analysisEndDate) : null}
                    onChange={(e) => {
                      if (e instanceof Date && !isNaN(e.getTime())) {
                        setAnalysisEndDate(
                          e ? e.toLocaleDateString("en-US") : ""
                        );
                      } else {
                        setAnalysisEndDate("");
                      }
                    }}
                    slotProps={{
                      textField: {
                        variant: "outlined",
                        margin: "dense",
                        size: "medium",
                        fullWidth: true,
                        error: !!errors.analysis_end_date,
                        helperText: errors.analysis_end_date,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <Tooltip
                  title={
                    "Enter values seperated by a comma or a space(e.g.,'123456789,987654321')"
                  }
                  placement={"top-start"}
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <TextField
                    size="medium"
                    margin="dense"
                    id="apt_job_ids"
                    label="APT Job IDs"
                    type="string"
                    fullWidth
                    variant="outlined"
                    value={jobIds}
                    onChange={(e) => setJobIds(e.target.value)}
                  />
                </Tooltip>
                <TextField
                  size="medium"
                  margin="dense"
                  id="pirrel_human_relevance_audit"
                  label="pIrrel/Audit"
                  type="string"
                  fullWidth
                  variant="outlined"
                  value={pirrelHumanRelevanceAudit}
                  onChange={(e) => setPirrelHumanRelevanceAudit(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="medium"
                  margin="dense"
                  id="ctr"
                  label="CTR"
                  type="string"
                  fullWidth
                  variant="outlined"
                  value={ctr}
                  onChange={(e) => setCtr(e.target.value)}
                />
                <TextField
                  size="medium"
                  margin="dense"
                  id="coverage"
                  label="Coverage"
                  type="string"
                  fullWidth
                  variant="outlined"
                  value={coverage}
                  onChange={(e) => setCoverage(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="medium"
                  margin="dense"
                  id="cpc"
                  label="CPC"
                  type="string"
                  fullWidth
                  variant="outlined"
                  value={cpc}
                  onChange={(e) => setCpc(e.target.value)}
                />
                <TextField
                  size="medium"
                  margin="dense"
                  id="acos"
                  label="ACos"
                  type="string"
                  fullWidth
                  variant="outlined"
                  value={acos}
                  onChange={(e) => setAcos(e.target.value)}
                />
              </Grid>
            </Grid>
            <Divider style={{ margin: "20px 0" }} />
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography
                  variant={"subtitle2"}
                  component={"div"}
                  align={"center"}
                >
                  SP Revenue
                </Typography>
                <Tooltip
                  title={"Enter value between 0-1"}
                  placement={"left-start"}
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <TextField
                    size="small"
                    margin="dense"
                    id="sp_revenue_prob"
                    label="Probability"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={spRevenueProb}
                    onChange={(e) =>
                      setSpRevenueProb(parseFloat(e.target.value))
                    }
                    inputProps={{ min: "0", max: "1", step: "0.0001" }}
                  />
                </Tooltip>
                <Tooltip
                  title={"Enter value between -100 and 100"}
                  placement={"left-start"}
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <TextField
                    size="small"
                    margin="dense"
                    id="sp_revenue_lift"
                    label="Lift(%)"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={spRevenueLift}
                    onChange={(e) =>
                      setSpRevenueLift(parseFloat(e.target.value))
                    }
                    inputProps={{ min: "0", max: "100", step: "0.001" }}
                  />
                </Tooltip>
                <CurrencyInput
                  label={"Impact"}
                  initialValue={spRevenueImpact}
                  onValueChange={(e) => setSpRevenueImpact(e)}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant={"subtitle2"}
                  component={"div"}
                  align={"center"}
                >
                  OPS
                </Typography>
                <Tooltip
                  title={"Enter value between 0-1"}
                  placement={"left-start"}
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <TextField
                    size="small"
                    margin="dense"
                    id="ops_prob"
                    label="Probability"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={opsProb}
                    onChange={(e) => setOpsProb(parseFloat(e.target.value))}
                    inputProps={{ min: "0", max: "1", step: "0.0001" }}
                  />
                </Tooltip>
                <Tooltip
                  title={"Enter value between -100 and 100"}
                  placement={"left-start"}
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <TextField
                    size="small"
                    margin="dense"
                    id="ops_lift"
                    label="Lift(%)"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={opsLift}
                    onChange={(e) => setOpsLift(parseFloat(e.target.value))}
                    inputProps={{ min: "-100", max: "100", step: "0.001" }}
                  />
                </Tooltip>
                <CurrencyInput
                  label={"Impact"}
                  initialValue={opsImpact}
                  onValueChange={(e) => setOpsImpact(e)}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant={"subtitle2"}
                  component={"div"}
                  align={"center"}
                >
                  GCCP
                </Typography>
                <Tooltip
                  title={"Enter value between 0-1"}
                  placement={"left-start"}
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <TextField
                    size="small"
                    margin="dense"
                    id="gccp_prob"
                    label="Probability"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={gccpProb}
                    onChange={(e) => setGccpProb(parseFloat(e.target.value))}
                    inputProps={{ min: "0", max: "1", step: "0.0001" }}
                  />
                </Tooltip>
                <Tooltip
                  title={"Enter value between -100 and 100"}
                  placement={"left-start"}
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <TextField
                    size="small"
                    margin="dense"
                    id="gccp_lift"
                    label="Lift(%)"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={gccpLift}
                    onChange={(e) => setGccpLift(parseFloat(e.target.value))}
                    inputProps={{ min: "-100", max: "100", step: "0.001" }}
                  />
                </Tooltip>
                <CurrencyInput
                  label={"Impact"}
                  initialValue={gccpImpact}
                  onValueChange={(e) => setGccpImpact(e)}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant={"subtitle2"}
                  component={"div"}
                  align={"center"}
                >
                  Net CP
                </Typography>
                <Tooltip
                  title={"Enter value between 0-1"}
                  placement={"left-start"}
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <TextField
                    size="small"
                    margin="dense"
                    id="net_cp_prob"
                    label="Probability"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={netCpProb}
                    onChange={(e) => setNetCpProb(parseFloat(e.target.value))}
                    inputProps={{ min: "0", max: "1", step: "0.0001" }}
                  />
                </Tooltip>
                <Tooltip
                  title={"Enter value between -100 and 100"}
                  placement={"left-start"}
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <TextField
                    size="small"
                    margin="dense"
                    id="net_cp_lift"
                    label="Lift(%)"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={netCpLift}
                    onChange={(e) => setNetCpLift(parseFloat(e.target.value))}
                    inputProps={{ min: "-100", max: "100", step: "0.001" }}
                  />
                </Tooltip>
                <CurrencyInput
                  label={"Impact"}
                  initialValue={netCpImpact}
                  onValueChange={(e) => setNetCpImpact(e)}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant={"subtitle2"}
                  component={"div"}
                  align={"center"}
                >
                  SP Clicks
                </Typography>
                <Tooltip
                  title={"Enter value between 0-1"}
                  placement={"left-start"}
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <TextField
                    size="small"
                    margin="dense"
                    id="sp_clicks_prob"
                    label="Probability"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={spClicksProb}
                    onChange={(e) =>
                      setSpClicksProb(parseFloat(e.target.value))
                    }
                    inputProps={{ min: "0", max: "1", step: "0.0001" }}
                  />
                </Tooltip>
                <Tooltip
                  title={"Enter value between -100 and 100"}
                  placement={"left-start"}
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <TextField
                    size="small"
                    margin="dense"
                    id="sp_clicks_lift"
                    label="Lift(%)"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={spClicksLift}
                    onChange={(e) =>
                      setSpClicksLift(parseFloat(e.target.value))
                    }
                    inputProps={{ min: "-100", max: "100", step: "0.001" }}
                  />
                </Tooltip>
                <NumberInput
                  label={"Impact"}
                  initialValue={spClicksImpact}
                  onValueChange={(e) => setSpClicksImpact(e)}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant={"subtitle2"}
                  component={"div"}
                  align={"center"}
                >
                  Paid Units
                </Typography>
                <Tooltip
                  title={"Enter value between 0-1"}
                  placement={"left-start"}
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <TextField
                    size="small"
                    margin="dense"
                    id="paid_units_prob"
                    label="Probability"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={paidUnitsProb}
                    onChange={(e) =>
                      setPaidUnitsProb(parseFloat(e.target.value))
                    }
                    inputProps={{ min: "0", max: "1", step: "0.0001" }}
                  />
                </Tooltip>
                <Tooltip
                  title={"Enter value between -100 and 100"}
                  placement={"left-start"}
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <TextField
                    size="small"
                    margin="dense"
                    id="paid_units_lift"
                    label="Lift(%)"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={paidUnitsLift}
                    onChange={(e) =>
                      setPaidUnitsLift(parseFloat(e.target.value))
                    }
                    inputProps={{ min: "-100", max: "100", step: "0.001" }}
                  />
                </Tooltip>
                <NumberInput
                  label={"Impact"}
                  initialValue={paidUnitsImpact}
                  onValueChange={(e) => setPaidUnitsImpact(e)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography
                  variant={"subtitle2"}
                  component={"div"}
                  align={"center"}
                >
                  CSales
                </Typography>
                <Tooltip
                  title={"Enter value between 0-1"}
                  placement={"left-start"}
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <TextField
                    size="small"
                    margin="dense"
                    id="csales_prob"
                    label="Probability"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={cSalesProb}
                    onChange={(e) => setCSalesProb(parseFloat(e.target.value))}
                    inputProps={{ min: "0", max: "1", step: "0.0001" }}
                  />
                </Tooltip>
                <Tooltip
                  title={"Enter value between -100 and 100"}
                  placement={"left-start"}
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <TextField
                    size="small"
                    margin="dense"
                    id="csales_lift"
                    label="Lift(%)"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={cSalesLift}
                    onChange={(e) => setCSalesLift(parseFloat(e.target.value))}
                    inputProps={{ min: "0", max: "100", step: "0.001" }}
                  />
                </Tooltip>
                <CurrencyInput
                  label={"Impact"}
                  initialValue={cSalesImpact}
                  onValueChange={(e) => setCSalesImpact(e)}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant={"subtitle2"}
                  component={"div"}
                  align={"center"}
                >
                  SB Revenue
                </Typography>
                <Tooltip
                  title={"Enter value between 0-1"}
                  placement={"left-start"}
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <TextField
                    size="small"
                    margin="dense"
                    id="sb_revenue_prob"
                    label="Probability"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={sbRevenueProb}
                    onChange={(e) =>
                      setSbRevenueProb(parseFloat(e.target.value))
                    }
                    inputProps={{ min: "0", max: "1", step: "0.0001" }}
                  />
                </Tooltip>
                <Tooltip
                  title={"Enter value between -100 and 100"}
                  placement={"left-start"}
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <TextField
                    size="small"
                    margin="dense"
                    id="sb_revenue_lift"
                    label="Lift(%)"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={sbRevenueLift}
                    onChange={(e) =>
                      setSbRevenueLift(parseFloat(e.target.value))
                    }
                    inputProps={{ min: "-100", max: "100", step: "0.001" }}
                  />
                </Tooltip>
                <CurrencyInput
                  label={"Impact"}
                  initialValue={sbRevenueImpact}
                  onValueChange={(e) => setSbRevenueImpact(e)}
                />
              </Grid>
              <Divider style={{ margin: "20px 0" }} />
            </Grid>
          </div>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              multiline
              rows={4}
              size="medium"
              margin="dense"
              id="comments"
              label="Comments"
              type="text"
              fullWidth
              variant="outlined"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: "20px 0" }} />
        <div>
          <Typography variant={"subtitle1"}>
            Rarely Used Fields
            <IconButton onClick={handleToggleSection}>
              {isSectionExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Typography>
          <Collapse in={isSectionExpanded}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <CurrencyInput
                  label="Expected Revenue Impact"
                  initialValue={revenueImpactPlannedWeblab}
                  onValueChange={(e) => setRevenueImpactPlannedWeblab(e)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="medium"
                  margin="dense"
                  id="irrelevance_rate_control"
                  label="Irrelevance Rate Control(%)"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={irrelevanceRateControl}
                  onChange={(e) => setIrrelevanceRateControl(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="medium"
                  margin="dense"
                  id="irrelevance_rate_treatment"
                  label="Irrelevance Rate Treatment(%)"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={irrelevanceRateTreatment}
                  onChange={(e) => setIrrelevanceRateTreatment(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          checked={smokeTest}
                          onChange={(e) => setSmokeTest(e.target.checked)}
                          color="primary"
                        />
                      }
                      label="Is Smoke Test"
                      labelPlacement="end"
                      sx={{ marginLeft: "auto" }} // Pushes the checkbox to the right
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Collapse>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddWeblabModal;
